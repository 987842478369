/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($){

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Framework = {
		// All pages
		'common'  : {
			init: function(){

				/**
				 * Sticky Nav yo!
				 * @type {*|Sticky}
				 */
				// var sticky = new Waypoint.Sticky({
				// 	element: $('.banner')[0]
				// });

				/**
				 * Set up the blog grid
				 * @type {*|HTMLElement}
				 */
				var grid = $('.masonry');
				if (grid.length > 0) {
					grid.gridalicious({
						gutter  : 15,
						width   : 330,
						selector: '.post'
					});
				}

				/**
				 * Back to top!
				 */
				var scroll_link = $('.back-to-top');
				if (scroll_link.length > 0) {
					scroll_link.on("click", function(){
						$('html, body').animate({scrollTop: 0}, 800);
						return false;
					});
				}

				/**
				 * Internal link scrolling
				 * @type {*|HTMLElement}
				 */
				var internal_links = $('a[href*="#"]:not([href="#"])');
				internal_links.click(function(){
					if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
						var target = $(this.hash);
						target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
						if (target.length) {
							$('html, body').animate({
								scrollTop: target.offset().top
							}, 1000);
							return false;
						}
					}
				});
			},

			finalize: function(){

			}
		},
		// Home page
		'home'    : {
			init    : function(){



			},
			finalize: function(){
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function(){
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire      : function(func, funcname, args){
			var fire;
			var namespace = Framework;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function(){
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm){
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
